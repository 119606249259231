<template>
  <KPage>
    <!--  -->
    <FilterBar />
    <!--  -->
    <v-card outlined>
      <div class="ma-0 pa-0" :class="{ col: isMobile, row: !isMobile }">
        <v-col cols="12" sm="6" class="pa-0">
          <SectionHeader
            title="Categorias"
            class="ma-2"
            :actions="rootActions"
            @click:add_root="onAddRootClick"
          />
          <CategoryList
            :items="rootList"
            is-root
            @click:select="(item) => (selected = item.id)"
            @click:remove="onRemoveClick"
            @click:edit="onEditClick"
          />
        </v-col>
        <v-divider :vertical="!isMobile"></v-divider>
        <v-col cols="12" sm="6" class="pa-0">
          <SectionHeader
            title="Subcategorias"
            class="ma-2"
            :actions="subActions"
            @click:add_sub="onAddSubClick"
          />
          <CategoryList
            :items="subList"
            @click:remove="onRemoveClick"
            @click:edit="onEditClick"
          />
        </v-col>
      </div>
    </v-card>

    <!-- Caixa de Diálogo -->
    <DialogSave ref="dialog_save" />
    <DialogDelete ref="dialog_delete" />

    <!--  -->
    <div style="height: 60px"></div>
  </KPage>
</template>

<script>
import KPage from "@/components/KPage";
import KForm from "@/components/KForm";
import KAvatar from "@/components/KAvatar";
import KChip from "@/components/KChip";
import SectionHeader from "@/components/SectionHeader";
import FAB from "@/components/FAB";

import FilterBar from "./FilterBar";

import DialogSave from "./DialogSave";
import DialogDelete from "./DialogDelete";
import CategoryList from "./CategoryList";

export default {
  components: {
    KPage,
    KForm,
    KAvatar,
    KChip,
    SectionHeader,
    FAB,

    FilterBar,

    DialogSave,
    DialogDelete,
    CategoryList,
  },

  data() {
    return {
      //
      loading: false,

      //
      dialog: {
        visible: true,
      },

      selected: null,
    };
  },

  computed: {
    /**
     * Alimenta os partidos de forma reativa
     */
    financial_categories() {
      return this.$store.getters["financial_categories/all"];
    },
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },

    rootList() {
      return this.$store.getters["financial_categories/all"].filter(
        item => !item.category_id
      );
    },
    subList() {
      return this.$store.getters["financial_categories/all"].filter(
        item => item.category_id && item.category_id === this.selected
      );
    },
    rootActions() {
      return [
        {
          eventName: "click:add_root",
          icon: "mdi-plus",
          label: "Nova Categoria",
        },
      ];
    },
    subActions() {
      return [
        {
          eventName: "click:add_sub",
          icon: "mdi-plus",
          label: "Nova Subcategoria",
        },
      ];
    },
  },

  //
  methods: {
    /**
     * Abre o dialog como cadastro
     */
    onAddRootClick() {
      this.$refs["dialog_save"].openToAdd();
    },

    /**
     * Abre o dialog como cadastro
     */
    onAddSubClick() {
      if (this.selected) {
        this.$refs["dialog_save"].openToAdd({ category_id: this.selected });
      } else {
        this.$message.show("Selecione uma categoria");
      }
    },

    /**
     * Abre o dialog como edição
     */

    onEditClick(data) {
      this.$refs["dialog_save"].openToEdit(data);
    },

    /**
     * Abre o dialog de exclusão
     */
    onRemoveClick(data) {
      this.$refs["dialog_delete"].openToRemove(data);
    },

    /**
     * Carrega os partidos caso ainda não estejam
     */
    async load() {
      try {
        this.loading = true;
        this.$loading.show();
        await this.$store.dispatch("financial_categories/all");
        this.loading = false;
        this.$loading.hide();
      } catch (error) {
        this.$message.serverError(error);
        this.loading = false;
        this.$loading.hide();
      }
    },
  },

  mounted() {
    this.load();
  },
};
</script>
