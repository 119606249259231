<template>
  <KDialog :loading="loading" :title="title" :visible="visible" showClose :actions="actions" @click:add="save"
    @click:edit="save" @click:close="close">
    <k-form ref="form">
      <v-row class="ma-0">
      <v-col cols="12" class="ma-0 pa-3 mt-1">
        <KAlert warning title="Atenção">
          Por questões de segurança, as informações de autenticação que já estão salvas não são exibidas. Todos os
          dados devem ser informados novamente.
        </KAlert>
      </v-col>

      <!--  -->
      <v-col cols="12">
        <SectionHeader title="Campos da autenticação" />
      </v-col>

      <!-- 
            Loop pelos campos 
        -->

      <v-col cols="12" v-for="field in fields" :key="field.name">

        <!-- Se for um campo de texto -->
        <v-text-field v-if="field.type === 'text'" dense outlined :rules="[$validation.isRequired]" :label="field.label"
          :hint="field.hint" :error-messages="errors[field.name]" v-model="data[field.name]" persistent-hint required />

        <!-- Se for uma textarea -->
        <v-textarea v-else-if="field.type === 'textarea'" dense outlined :rules="[$validation.isRequired]"
          :label="field.label" :hint="field.hint" :error-messages="errors[field.name]" v-model="data[field.name]"
          persistent-hint required />



      </v-col>

      </v-row>
    </k-form>
  </KDialog>
</template>

<script>
import KDialog from "@/components/KDialog";
import KForm from "@/components/KForm";
import KAlert from "@/components/KAlert";
import SectionHeader from "@/components/SectionHeader";
import KInputMoney from "@/components/KInput/Money";

export default {
  components: {
    KDialog,
    KForm,
    KInputMoney,
    SectionHeader,
    KAlert,
  },

  data() {
    return {
      visible: false,

      // 
      fields: [],
      data: {},
      id: null,

      //
      isEditing: false,
      title: null,

      loading: false,

      //
      errors: {
        fine: {},
        interest: {},
      },
    };
  },

  computed: {
    actions() {
      return [
        {
          eventName: "click:edit",
          label: "Salvar Alterações",
        },
      ];
    },

    payload() {
      return {
        auth: {
          ...this.data
        }
      };
    },
  },

  methods: {
    /*******************************************************
     *
     *  FUNÇÕES PARA SALVAR E EDITAR OS DADOS NO STORE
     *
     *******************************************************/

    /**
     * Salva os dados no Store
     */
    async save() {
      try {
        //
        // Se as demais validações passaram
        if (this.$refs["form"].validate()) {
          this.loading = true;
          //
          await this.$store.dispatch("financial_connections/updateAuth", {
            id: this.id,
            payload: this.payload,
          });

          this.$message.success("Registro salvo com sucesso");
          this.close();
        } else {
          this.$message.error("Verifique os campos marcados em vermelho");
        }

        //
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.errors = this.$message.serverError(error);
      }
    },

    /*******************************************************
     *
     *  FUNÇÕES BÁSICAS DE QUALQUER DIALOG
     *
     *******************************************************/

    /**
     * Abre o dialog preparado para alteerar os dados
     */
    openToEdit(payload) {
      // Remove todos os observáveis
      payload = this.$clone(payload);

      // Altera o título
      this.title = `Editar Autenticação de "${payload.name}"`;

      // Mescla os dados recebidos para não alterar o que está para trás
      this.id = payload.id
      this.fields = payload.gateway.auth_template
      this.open();
    },

    /**
     * Abre o dialog
     */
    open() {
      this.visible = true;
    },

    /**
     * Fecha o dialog
     */
    close() {
      this.$refs.form.resetValidation();
      this.visible = false;
      this.clear();
    },

    /**
     * Ao clicar em adicionar instruções
     */
    onAddInstructionClick() {
      this.settings.instructions.push("");
    },

    /**
     * Ao clicar em remover uma instrução
     */
    onRemoveInstructionClick(index) {
      this.$delete(this.settings.instructions, index);
    },

    /**
     * Limpa os dados do dialog
     */
    clear() {
      this.id = null
      this.data = {};
      this.fields = [];
    },
  },
};
</script>

<style></style>
