<template>
  <v-card elevation="0" style="height: 50vh; overflow: auto">
    <v-list v-if="items.length" class="bordered py-0">
      <v-list-item
        v-for="item in items"
        :key="item.id"
        @click.stop="onRowClick(item)"
        :class="item.id === selected ? 'grey lighten-3' : ''"
      >
        <v-list-item-avatar v-if="isRoot">
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            {{ item.name }} <KChip>#{{ item.id }}</KChip>
          </v-list-item-title>
          <v-list-item-subtitle>
            <KChip v-if="item.is_blocked" orange>Bloqueado</KChip>
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action v-if="!item.is_blocked">
          <div class="flex row ma-0 flex-nowrap">
            <v-btn icon @click.stop="onEditClick(item)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn icon @click.stop="onRemoveClick(item)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </div>
        </v-list-item-action>
      </v-list-item>
      <!-- <v-divider></v-divider> -->
    </v-list>

    <div class="text-center grey--text" v-else>Nada a exibir</div>
  </v-card>
</template>

<script>
import KChip from "@/components/KChip";
export default {
  name: "category-list",
  components: { KChip },
  props: {
    items: Array,
    isRoot: Boolean,
  },

  data() {
    return {
      selected: null,
    };
  },

  methods: {
    onRowClick(item) {
      if (this.isRoot) {
        this.selected = item.id;
        this.$emit("click:select", item);
      } else this.$emit("click:edit", item);
    },
    onEditClick(item) {
      this.$emit("click:edit", item);
    },
    onRemoveClick(item) {
      this.$emit("click:remove", item);
    },
  },
};
</script>

<style>
</style>
