<template>
  <KDialog :loading="loading" :title="title" :visible="visible" showClose :actions="actions" @click:add="save"
    @click:edit="save" @click:close="close">
    <k-form ref="form">
      <v-row class="ma-0">
        <!-- Gateway -->
        <v-col v-if="!isEditing" cols="12">
          <v-select :items="gateways" item-value="id" item-text="name" dense outlined :rules="[$validation.isRequired]"
            label="Gateway"
            hint="Selecione o Gateway ao qual esta conexão estará vinculada"
            :error-messages="errors.gateway_id" v-model="data.gateway_id" persistent-hint required></v-select>
        </v-col>
        <v-col v-else cols="12">
          <KChip yellow>{{ data.gateway && data.gateway.name }}</KChip>
        </v-col>

        <!-- Nome da Configuração -->
        <v-col cols="12">
          <v-text-field dense outlined :rules="[$validation.isRequired]" label="Nome" :error-messages="errors.name"
            v-model="data.name" persistent-hint required></v-text-field>
        </v-col>

        <v-col cols="12" sm="6">
          <v-switch inset class="ma-0" :rules="[$validation.isRequired]" label="Está ativo?"
            hint="Define se esta conexão está ativa e pode ser usada para gerar cobranças"
            :error-messages="errors.is_active" v-model="data.is_active" persistent-hint required></v-switch>
        </v-col>

        <v-col cols="12" sm="6">
          <v-switch inset class="ma-0" :rules="[$validation.isRequired]" label="É Sandbox?"
            hint="Define se esta conexão usa dados de sandbox" :error-messages="errors.is_sandbox"
            v-model="data.is_sandbox" persistent-hint required></v-switch>
        </v-col>
      </v-row>
    </k-form>
  </KDialog>
</template>

<script>
import KDialog from "@/components/KDialog";
import KForm from "@/components/KForm";
import KChip from "@/components/KChip";
import SectionHeader from "@/components/SectionHeader";
import KInputMoney from "@/components/KInput/Money";
import { data } from "vue-echarts";

export default {
  components: {
    KDialog,
    KForm,
    KChip,
    KInputMoney,
    SectionHeader,
  },

  data() {
    return {
      // Define se está visível
      visible: false,
      // Armazena os dados que virão via função para preencher
      data: {},

      //
      isEditing: false,
      title: null,

      loading: false,

      //
      errors: {},
    };
  },

  computed: {
    actions() {
      return [
        {
          eventName: "click:edit",
          label: "Salvar Alterações",
        },
      ];
    },

    gateways() {
      return this.$store.getters['financial_gateways/allActive']
    },

    payload() {
      return {
        gateway_id: this.data.gateway_id,
        name: this.data.name,
        is_active: this.data.is_active,
        is_sandbox: this.data.is_sandbox
      };
    },
  },

  methods: {
    /*******************************************************
     *
     *  FUNÇÕES PARA SALVAR E EDITAR OS DADOS NO STORE
     *
     *******************************************************/

    /**
     * Salva os dados no Store
     */
    async save() {
      try {
        //
        // Se as demais validações passaram
        if (this.$refs["form"].validate()) {
          this.loading = true;
          //
          await this.$store.dispatch("financial_connections/save", {
            id: this.data.id,
            payload: this.payload,
          });

          this.$message.success("Registro salvo com sucesso");
          this.close();
        } else {
          this.$message.error("Verifique os campos marcados em vermelho");
        }

        //
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.errors = this.$message.serverError(error);
      }
    },

    /*******************************************************
     *
     *  FUNÇÕES BÁSICAS DE QUALQUER DIALOG
     *
     *******************************************************/

    /**
     * Abre o dialog preparado para alteerar os dados
     */
    openToEdit(payload) {
      // Remove todos os observáveis
      payload = this.$clone(payload);

      // Altera o título
      this.title = `Editar "${payload.name}"`;

      // Mescla os dados recebidos para não alterar o que está para trás
      this.data = {
        id: payload.id,
        name: payload.name,
        is_active: payload.is_active,
        is_sandbox: payload.is_sandbox,
        gateway_id: payload.gateway_id,
        gateway: payload.gateway,
      };

      // Define se é uma edição
      this.isEditing = true;

      // Abre por último, depois que está tudo preenchido
      this.visible = true;
    },

    /**
     * Abre o dialog
     */
    open() {
      this.title = `Nova conexão`;
      this.data = {
        is_active:true,
        is_sandbox: false
      }
      this.visible = true;
      this.isEditing = false
    },

    /**
     * Fecha o dialog
     */
    close() {
      this.$refs.form.resetValidation();
      this.visible = false;
      this.clear();
    },

    /**
     * Ao clicar em adicionar instruções
     */
    onAddInstructionClick() {
      this.settings.instructions.push("");
    },

    /**
     * Ao clicar em remover uma instrução
     */
    onRemoveInstructionClick(index) {
      this.$delete(this.settings.instructions, index);
    },

    /**
     * Limpa os dados do dialog
     */
    clear() {
      this.data = {};
    },
  },
};
</script>

<style></style>
