<template>
  <KDialog
    :title="title"
    :loading="loading"
    :visible="visible"
    @click:close="close"
  >
    <v-col cols="12">
      <SectionHeader title="Dados do webhook" />
    </v-col>

    <v-col cols="12">
      <v-card outlined rounded="lg">
        <ExplicatedAction
          title="URL do webhook"
          :description="webhook.url"
          :show-button="false"
          color="primary"
        />
        <v-divider></v-divider>
        <ExplicatedAction
          title="Última atualização"
          :description="webhook.date"
          :show-button="false"
          color="primary"
        />
        <v-divider></v-divider>
        <ExplicatedAction
          title="Atualizar webhook"
          color="primary"
          event-name="click:update"
          @click:update="update"
          description="Conecta-se ao gateway de pagamento e atualiza o webhook"
        />
      </v-card>
    </v-col>
  </KDialog>
</template>

<script>
import KDialog from "@/components/KDialog";
import SectionHeader from "@/components/SectionHeader";
import ExplicatedAction from "@/components/ExplicatedAction";
import moment from "moment";

export default {
  components: {
    KDialog,
    SectionHeader,
    ExplicatedAction
  },

  data() {
    return {
      //
      data: {},
      // Dialog
      loading: false,
      title: null,
      visible: false
    };
  },

  computed: {

    webhook(){
      const webhook = this.data.settings && this.data.settings.webhook;
      if(webhook){
        return {
          url: webhook.webhookUrl,
          date: moment(webhook.created_at).format('DD/MM/YY HH:mm'),
        }
      }

      return {
        url: '-',
        date: '-'
      }
    }


  },

  methods: {
    async update() {
      try {
        console.log("entrou");
        this.loading = true;

        await this.$store.dispatch(
          "financial_connections/updateWebhook",
          this.data.id
        );

        this.$message.success("Webhook atualizado com sucesso");
        this.loading = false;
        this.close();
      } catch (error) {
        // console.log(error);
        this.$message.serverError(error);
        this.loading = false;
        this.close();
      }
    },

    /**
     * Abre o dialog preparado para alteerar os dados
     */
    open(payload) {
      // Mescla os dados recebidos para não alterar o que está para trás
      this.data = this.$clone(payload);

      this.title = `Atualizar Webhook ${payload.name}`;

      // Abre por último, depois que está tudo preenchido
      this.visible = true;
    },

    /**
     * Fecha o dialog
     */
    close() {
      this.visible = false;
    },

    /**
     * Limpa os dados do dialog
     */
    clear() {
      this.data = {};
    }
  }
};
</script>

<style>
</style>
