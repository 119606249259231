<template>
  <KPage>
    <!--  -->
    <FilterBar @click:new="onNewConnectionClick" />

    <!--  -->
    <v-card outlined class="overflow-hidden">
      <v-row class="ma-0">
        <v-col cols="12" xs="12" class="pa-0">
          <v-list class="pa-0 bordered">
            <v-list-item v-for="(item, key) in financial_connections" :key="key">
              <v-list-item-avatar>
                <v-icon>mdi-connection</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.name }} <KChip>#{{ item.id }} </KChip>
                </v-list-item-title>
                <v-list-item-subtitle>
                  <!--  -->
                  <KChip v-if="item.is_active" blue>Ativo</KChip>
                  <KChip v-else>Inativo</KChip>
                  <!--  -->
                  <KChip yellow icon="mdi-connection">{{ item.gateway && item.gateway.name }} </KChip>
                  <!--  -->
                  <KChip v-if="item.is_sandbox" pink>Sandbox</KChip>
                  <!--  -->
                  <KChip v-if="isDefaultConnection(item)" amber>Conexão Padrão</KChip>
                </v-list-item-subtitle>
              </v-list-item-content>
              <!--  -->
              <v-list-item-action>
                <div class="flex row ma-0 flex-nowrap">
                  <v-btn icon @click.stop="onConvertOnDefaultConnectionClick(item)" title="Tornar padrão"
                    :color="isDefaultConnection(item) ? 'amber' : ''">
                    <v-icon>mdi-octagram</v-icon>
                  </v-btn>

                  <v-btn icon @click.stop="onEditConnectionClick(item)" title="Alterar">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn icon @click.stop="onDialogSettingsClick(item)" title="Configurações">
                    <v-icon>mdi-cog</v-icon>
                  </v-btn>
                  <v-btn icon @click.stop="onDialogAuthClick(item)" title="Autenticação">
                    <v-icon>mdi-key</v-icon>
                  </v-btn>
                  <v-btn icon @click.stop="onWebhookClick(item)" title="Webhook" :disabled="!item.gateway.has_webhook">
                    <v-icon>mdi-webhook</v-icon>
                  </v-btn>
                </div>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-card>

    <!-- Caixa de Diálogo -->
    <DialogSave ref="dialog_save" />
    <DialogAuth ref="dialog_auth" />
    <DialogSettings ref="dialog_settings" />
    <DialogWebhook ref="dialog_webhook" />

    <!--  -->
  </KPage>
</template>

<script>
import KPage from "@/components/KPage";
import KForm from "@/components/KForm";
import KAvatar from "@/components/KAvatar";
import KChip from "@/components/KChip";

import FilterBar from "./FilterBar";

import DialogSave from "./DialogSave";
import DialogWebhook from "./DialogWebhook";
import DialogAuth from "./DialogAuth";
import DialogSettings from "./DialogSettings";

export default {
  name: 'ConnectionPageIndex',
  components: {
    KPage,
    KForm,
    KAvatar,
    KChip,

    FilterBar,

    DialogSave,
    DialogAuth,
    DialogSettings,
    DialogWebhook
  },

  data() {
    return {
      //
      loading: false,

      //
      dialog: {
        visible: true
      }
    };
  },

  computed: {
    /**
     * Alimenta os partidos de forma reativa
     */
    financial_connections() {
      return this.$store.getters["financial_connections/all"];
    },

    default_connection() {
      const key = 'DEFAULT_FINANCIAL_GATEWAY_CONNECTION_ID';
      const all = this.$store.getters['settings/all']

      return all.find(item => item.name == key) || {}
    }
  },

  //
  methods: {

    /**
     * Carrega os partidos caso ainda não estejam
     */
    async load() {
      try {
        this.loading = true;
        this.$loading.show();
        await this.$store.dispatch("financial_connections/all");
        this.loading = false;
        this.$loading.hide();
      } catch (error) {
        this.$message.serverError(error);
        this.loading = false;
        this.$loading.hide();
      }
    },

    /**
     * Abre o dialog como edição
     */
    onEditConnectionClick(data) {
      this.$refs["dialog_save"].openToEdit(data);
    },

    /**
     * 
     * 
     */
    onNewConnectionClick() {
      this.$refs["dialog_save"].open();
    },


    onWebhookClick(item) {
      this.$refs["dialog_webhook"].open(item);
    },

    onDialogAuthClick(item) {
      this.$refs["dialog_auth"].openToEdit(item);
    },

    onDialogSettingsClick(item) {
      this.$refs["dialog_settings"].openToEdit(item);
    },

    // Converte esta conexão na conexão padrão do sistema
    async onConvertOnDefaultConnectionClick(item) {
      await this.$store.dispatch("settings/save", {
        payload: { id: this.default_connection.id, value: item.id },
        editing: true
      });
    },

    // Verifica se é a conexão padrão
    isDefaultConnection(item) {
      return +this.default_connection.value === item.id;
    },
  },

  mounted() {
    this.load();
    this.$store.dispatch('financial_gateways/all')
  }
};
</script>
