<template>
  <KDialog
    :loading="loading"
    :title="title"
    :visible="visible"
    showClose
    :actions="actions"
    @click:add="save"
    @click:edit="save"
    @click:close="close"
  >
    <k-form ref="form">
      <v-row class="ma-0">
        

        <!--  -->
        <v-col cols="12">
          <SectionHeader title="Multa" subtitle="Multa por atraso" />
        </v-col>

        <!-- tipo -->
        <v-col cols="12" sm="6" class="py-0">
          <v-select
            dense
            outlined
            :rules="[$validation.isRequired]"
            label="Tipo"
            :items="types"
            item-text="text"
            item-value="key"
            :error-messages="errors.fine && errors.fine.type"
            v-model="fine.type"
            persistent-hint
            required
          ></v-select>
        </v-col>

        <!-- valor -->
        <v-col cols="12" sm="6" class="py-0">
          <KInputMoney
            dense
            outlined
            :rules="[$validation.isRequired]"
            label="Valor"
            :error-messages="errors.fine && errors.fine.value"
            v-model="fine.value"
            persistent-hint
            required
          ></KInputMoney>
        </v-col>

        <!--  -->
        <v-col cols="12">
          <SectionHeader title="Juros" subtitle="Juros por dia de atraso" />
        </v-col>

        <!-- Tipo -->
        <v-col cols="12" sm="6" class="py-0">
          <v-select
            dense
            outlined
            :rules="[$validation.isRequired]"
            label="Tipo"
            :items="types"
            item-text="text"
            item-value="key"
            :error-messages="errors.interest && errors.interest.type"
            v-model="interest.type"
            persistent-hint
            required
          ></v-select>
        </v-col>

        <!-- Valor -->
        <v-col cols="12" sm="6" class="py-0">
          <KInputMoney
            dense
            outlined
            :rules="[$validation.isRequired]"
            label="Valor"
            :error-messages="errors.interest && errors.interest.value"
            v-model="interest.value"
            persistent-hint
            required
          ></KInputMoney>
        </v-col>

        <!--  -->
        <v-col cols="12">
          <SectionHeader title="Configurações" />
        </v-col>

        <!--  -->
        <v-col cols="12">
          <v-text-field
            dense
            outlined
            type="number"
            hide-spin-buttons
            :rules="[$validation.isRequired]"
            label="Dias para expiração"
            hint="Quantidade de dias após o vencimento para o boleto expirar"
            :error-messages="errors.pay_until"
            v-model="settings.pay_until"
            persistent-hint
            required
          ></v-text-field>
        </v-col>

        <!--  -->
        <v-col cols="12">
          <SectionHeader
            title="Instruções"
            subtitle="Adicione instruções para serem exibidas no boleto"
          />
        </v-col>

        <!--  -->
        <v-col
          v-for="(instruction, i) in settings.instructions"
          :key="i"
          cols="12"
          class="pb-0 pt-4"
        >
          <v-text-field
            dense
            outlined
            :rules="[$validation.isRequired]"
            label="Instruções"
            hint="Quantidade de dias após o vencimento para o boleto expirar"
            :error-messages="errors.pay_until"
            v-model="settings.instructions[i]"
            persistent-hint
            counter="70"
            required
            append-outer-icon="mdi-delete"
            @click:append-outer="onRemoveInstructionClick(i)"
          ></v-text-field>
        </v-col>

        <!--  -->
        <v-col cols="12" align="center" class="pa-0">
          <v-btn @click="onAddInstructionClick" color="accent" text>
            <v-icon left>mdi-plus-circle-outline</v-icon>
            Adicionar Instrução</v-btn
          >
        </v-col>
      </v-row>
    </k-form>
  </KDialog>
</template>

<script>
import KDialog from "@/components/KDialog";
import KForm from "@/components/KForm";
import SectionHeader from "@/components/SectionHeader";
import KInputMoney from "@/components/KInput/Money";

export default {
  components: {
    KDialog,
    KForm,
    KInputMoney,
    SectionHeader,
  },

  data() {
    return {
      // Define se está visível
      visible: false,
      // Armazena os dados que virão via função para preencher
      data: {},
      fine: { type: "percent", value: 0 },
      interest: { type: "percent", value: 0 },
      settings: {
        instructions: [],
        pay_until: 60,
      },

      //
      isEditing: false,
      title: null,

      loading: false,

      //
      errors: {
        fine: {},
        interest: {},
      },
    };
  },

  computed: {
    actions() {
      return [
        {
          eventName: "click:edit",
          label: "Salvar Alterações",
        },
      ];
    },

    types() {
      return [
        { key: "percent", text: "Porcentagem" },
        { key: "value", text: "Valor fixo" },
      ];
    },

    payload() {
      return {
        fine: this.fine,
        interest: this.interest,
        instructions: this.settings.instructions.filter(
          instruction => instruction
        ),
        pay_until: this.settings.pay_until,
      };
    },
  },

  methods: {
    /*******************************************************
     *
     *  FUNÇÕES PARA SALVAR E EDITAR OS DADOS NO STORE
     *
     *******************************************************/

    /**
     * Salva os dados no Store
     */
    async save() {
      try {
        //
        // Se as demais validações passaram
        if (this.$refs["form"].validate()) {
          this.loading = true;
          //
          await this.$store.dispatch("financial_connections/updateSettings", {
            id: this.data.id,
            payload: this.payload,
          });

          this.$message.success("Registro salvo com sucesso");
          this.close();
        } else {
          this.$message.error("Verifique os campos marcados em vermelho");
        }

        //
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.errors = this.$message.serverError(error);
      }
    },

    /*******************************************************
     *
     *  FUNÇÕES BÁSICAS DE QUALQUER DIALOG
     *
     *******************************************************/

    /**
     * Abre o dialog preparado para alteerar os dados
     */
    openToEdit(payload) {
      // Remove todos os observáveis
      payload = this.$clone(payload);

      // Altera o título
      this.title = `Editar "${payload.name}"`;

      if(typeof payload.settings === 'string') payload.settings = JSON.parse(payload.settings)
      

      // Mescla os dados recebidos para não alterar o que está para trás
      this.data = {
        id: payload.id,
      };
      this.settings = {
        pay_until: payload.settings && payload.settings.pay_until,
        instructions: payload.settings && payload.settings.instructions||[],
      };
      this.fine = payload.settings && payload.settings.fine || {};
      this.interest = payload.settings && payload.settings.interest || {};

      // Define se é uma edição
      this.isEditing = true;

      // Abre por último, depois que está tudo preenchido
      this.open();
    },

    /**
     * Abre o dialog
     */
    open() {
      this.visible = true;
    },

    /**
     * Fecha o dialog
     */
    close() {
      this.$refs.form.resetValidation();
      this.visible = false;
      this.clear();
    },

    /**
     * Ao clicar em adicionar instruções
     */
    onAddInstructionClick() {
      this.settings.instructions.push("");
    },

    /**
     * Ao clicar em remover uma instrução
     */
    onRemoveInstructionClick(index) {
      this.$delete(this.settings.instructions, index);
    },

    /**
     * Limpa os dados do dialog
     */
    clear() {
      this.data = {};
    },
  },
};
</script>

<style>
</style>
