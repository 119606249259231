<template>
  <KPage>
    <!--  -->
    <FilterBar />

    <!--  -->
    <v-card outlined class="overflow-hidden">
      <v-row class="ma-0">
        <v-col cols="12" xs="12" class="pa-0">
          <v-list class="pa-0 bordered">
            <v-list-item v-for="(item, key) in financial_statuses" :key="key">
              <v-list-item-avatar>
                <v-icon :color="item.color">mdi-sticker-check</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-text="item.name"></v-list-item-title>
                <v-list-item-subtitle>
                  <!-- Quando ao vínculo com os gateways -->
                  <KChip v-if="!item.gateway_id" blue> Status Raiz</KChip>
                  <KChip v-else yellow> Gateway: {{ item.gateway_id }} </KChip>
                  <!--  -->
                  <KChip v-if="item.status !== null"
                    >Status: {{ item.status }}</KChip
                  >
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-card>
  </KPage>
</template>

<script>
import KPage from "@/components/KPage";
import KChip from "@/components/KChip";

import FilterBar from "./FilterBar";

export default {
  components: {
    KPage,
    KChip,

    FilterBar,
  },

  data() {
    return {
      //
      loading: false,

      //
      dialog: {
        visible: true,
      },
    };
  },

  computed: {
    /**
     * Alimenta os partidos de forma reativa
     */
    financial_statuses() {
      return this.$store.getters["financial_statuses/all"];
    },
  },

  //
  methods: {
    /**
     * Carrega os partidos caso ainda não estejam
     */
    async load() {
      try {
        this.loading = true;
        this.$loading.show();
        await this.$store.dispatch("financial_statuses/all");
        this.loading = false;
        this.$loading.hide();
      } catch (error) {
        this.$message.serverError(error);
        this.loading = false;
        this.$loading.hide();
      }
    },
  },

  mounted() {
    this.load();
  },
};
</script>
