<template>
  <v-toolbar flat class="pl-2">
    <!-- Botão de Atualizar -->
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn icon @click="refresh" v-on="on">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </template>
      Atualizar Dados
    </v-tooltip>
  </v-toolbar>
</template>

<script>
export default {
  data() {
    return {
      query: {
        q: null
      }
    };
  },

  methods: {
    /**
     * Atualiza
     */
    async refresh() {
      try {
        await this.$nextTick();
        this.$loading.show();
        await this.$store.dispatch("financial_methods/refresh");
        this.$loading.hide();
      } catch (error) {
        this.$message.serverError(error);
        this.$loading.hide();
        console.log(error);
      }
    },

    onClear() {
      this.refresh();
    }
  }
};
</script>

<style>
</style>
